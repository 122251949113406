'use strict';

require('../../touch')(); //touch events

var imagesLoaded = require('imagesloaded'),
    dialog = require('../../dialog'),
    util = require('../../util');

var zoomMediaQuery = matchMedia('(min-width: 960px)');

/**
 * @description Enables the zoom viewer on the product detail page
 * @param zmq {Media Query List}
 */
var loadZoom = function (zmq) {
    var $imgZoom = $('#pdpMain .main-image'),
        hiresUrl;
    if (!zmq) {
        zmq = zoomMediaQuery;
    }
    if ($imgZoom.length === 0 || dialog.isActive() || util.isMobile() || !zmq.matches) {
        // remove zoom
        $imgZoom.trigger('zoom.destroy');
        return;
    }
    hiresUrl = $imgZoom.attr('href');

    if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1 && zmq.matches) {
        $imgZoom.zoom({
            url: hiresUrl
        });
    }
};

zoomMediaQuery.addListener(loadZoom);

/**
 * @description Smart Zoom functionality for mobile slider
 */
var eventSmartZoom = function () {
    // Add Event to 'Zoom' button
    $('.pdp-mobile-slider-zoom-btn').on('click', function (e) {
        e.stopPropagation();
        var $zoomWrapper = $('.mobile-zoom-wrapper');
        if (!$zoomWrapper.length) {
            createSmartZoom();
            increaseSmartZoom();
            $(this).addClass('visually-hidden');
        } else {
            destroySmartZoom();
        }
    });

    // Activate 'close zoom' button
    $('.smart-zoom-close-btn').on('click', function (e) {
        e.stopPropagation();
        var $zoomWrapper = $('.mobile-zoom-wrapper');

        if ($zoomWrapper.length) {
            $('.pdp-mobile-slider-zoom-btn').removeClass('visually-hidden');
            destroySmartZoom();
        }
    });
    // Destroy Smart Zoom on resize
    $(window).on('orientationchange resize', function () {
        destroySmartZoom();
    });
};

/**
 * @description Create Smart Zoom functionality for mobile slider
 */
var createSmartZoom = function () {
    // if doesn't exist
    if (!$('.pdp-mobile-slider').length) {return;}
    // if it still has 'data-lazy' attribute and doesn't has 'src'
    if ($('.pdp-mobile-slider .slick-active img').attr('src') === undefined) {return;}
    // if it is still appearing in slider
    if ($('.pdp-mobile-slider .slick-active img').css('opacity') != 1) {return;}

    var $activeImage = $('.pdp-mobile-slider .slick-active img').clone();

    $activeImage.addClass('mobile-zoom-image');
    $('.pdp-mobile-slider').append($activeImage);

    // show 'close zoom' button
    $('.smart-zoom-close-btn').addClass('active');

    //create smart zoom
    $activeImage.smartZoom({
        'maxScale': 2.5,
        'containerClass': 'mobile-zoom-wrapper'
    });
};

/**
 * @description Destroy Smart Zoom on mobile slider
 */
var destroySmartZoom = function () {
    if (!$('.mobile-zoom-wrapper').length) {return;}

    var $zoomWrapper = $('.mobile-zoom-wrapper');
    var $activeImage = $zoomWrapper.find('img');

    // hide 'close zoom' button
    $('.smart-zoom-close-btn').removeClass('active');

    $zoomWrapper.fadeOut('500', function () {
        $zoomWrapper.find('img').smartZoom('destroy');
        $activeImage.remove();
    });
};

/**
 * @description Increase Size for Smart Zoom on mobile slider
 */
var increaseSmartZoom = function () {
    var $activeImage = $('.mobile-zoom-wrapper img');
    $activeImage.smartZoom('zoom', 2.5);
};

/**
 * @description Decrease Size for Smart Zoom on mobile slider
 */
var decreaseSmartZoom = function () {
    var $activeImage = $('.mobile-zoom-wrapper img');
    $activeImage.smartZoom('zoom', -2.5);
};

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
var setMainImage = function (atts) {
    $('#pdpMain .plus-image-link').attr({
        href: atts.url,
        title: atts.title
    });
    $('#pdpMain .primary-image').attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title
    });
    if (!dialog.isActive() && !util.isMobile()) {
        $('#pdpMain .main-image').attr('href', atts.hires);
    }
    loadZoom();
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
var replaceImages = function () {
    var $newImages = $('#update-images'),
    $detailsSection = $('.primary-container'),
    headerHeight = $('#top-banner').height(),
    $mainImage = $('.main-image'),
    tmbRight = ($(window).width() - $('#pdpMain').width()) / 2,
        $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();
    loadZoom();
    if (util.getViewport() >= 768 && $('#pdpMain').length) {
        if (($(document).scrollTop() + headerHeight) >= $('.product-col-1').offset().top) {
            $('.primary-container').find('#thumbnails').addClass('fixed-position').css('right', tmbRight);
        } else {
            $detailsSection.find('#thumbnails').removeClass('fixed-position').css('right', 'auto');
        }
        $(window).scrollTop($(window).scrollTop() + 1);
    }
};

/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description Creates a carousel out of the thumbnails that are setup for each product
 */
var setupCarousel = function () {
    // Setup the carousel of thumbnails under the main image
    var isVertical = $('#wrapper').is('.golfshoes') || $('#wrapper').is('.apparel');
    $('.product-thumbnails .carousel').slick({
        vertical: isVertical,
        infinite: false,
        slidesToShow: 12,
        slidesToScroll: 1,
        swipe: true,
        touchMove: false,
        prevArrow: '#thumbnails .slick-prev',
        nextArrow: '#thumbnails .slick-next',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 12,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true
                }
            }
        ]
    });

    // Carousel for Mobile
    var $pdpMobileSlider = $('.pdp-mobile-slider');
    $pdpMobileSlider.on('init', function () {
        // refresh on load first image
        $pdpMobileSlider.find('.slick-active img').on('load', function () {
            $('.pdp-mobile-slider').resize();
        });

        // init zoom functionality
        eventSmartZoom();
        // show slider and hide preloader
        $pdpMobileSlider.removeClass('hide-on-load');
        $('.mobile-slider-loader').hide();
    });
    // destroy SMART ZOOM on sliding
    $pdpMobileSlider.on('beforeChange swipe', function () {
        destroySmartZoom();
    });
    $pdpMobileSlider.slick({
        lazyload: 'progressive',
        arrows: false,
        dots: false,
        infinite: true,
        touchThreshold: 5,
        appendDots: $('.pdp-mobile-slider-dots')
    });
};

var loadPrevImage = function () {
    var $selectedThumbnail = $('.thumb.selected');
    if ($selectedThumbnail.is('.first-thumb')) {
        $('.thumb.last-thumb .productthumbnail').click();
    } else {
        $selectedThumbnail.prev().find('.productthumbnail').click();
    }
};

var loadNextImage = function () {
    var $selectedThumbnail = $('.thumb.selected');
    if ($selectedThumbnail.is('.last-thumb')) {
        $('.thumb.first-thumb .productthumbnail').click();
    } else {
        $selectedThumbnail.next().find('.productthumbnail').click();
    }
};

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
    setupCarousel();
    if (dialog.isActive() || util.isMobile()) {
        $('#pdpMain .main-image').removeAttr('href');
    }
    loadZoom();
    // handle product thumbnail click event
    $('#pdpMain').on('click', '.productthumbnail', function (e) {
        e.preventDefault();
        var isScroll = $(this).parents('#thumbnails').hasClass('scroll-thumbnails');
        // switch indicator
        $(this).closest('.product-thumbnails').find('.thumb.selected').removeClass('selected');
        $(this).closest('.thumb').addClass('selected');
        if (isScroll) {
            var link = $(this).parent().attr('href');
            $('body,html').animate(
                {'scrollTop': $(link).offset().top}, 600
            );
        } else {
            setMainImage($(this).data('lgimg'));
        }
    });

    // Handle the previous product image click event
    $('#pdpMain').on('click', '.product-primary-image .prev-image-button', loadPrevImage);
    $('#pdpMain').on('swiperight swipedown', '.product-primary-image', loadPrevImage);

    // Handle the next product image click event
    $('#pdpMain').on('click', '.product-primary-image .next-image-button', loadNextImage);
    $('#pdpMain').on('swipeleft swipeup', '.product-primary-image', loadNextImage);

    //prevent finger scroll for swipeup/down
    $('#pdpMain').on('touchstart, click', '.product-primary-image a:not(.plus-image-link)', function (e) {e.preventDefault();});

    $(document).ready(function () {
        $('#color-swatches').on('init', function () {
            $('#color-swatches .swatchanchor').css('height', 'auto').syncHeight();
            $(window).resize();
        });
        var resizeTimer;
        $(window).on('resize', function () {
            clearTimeout(resizeTimer);
            if ($('#color-swatches .swatchanchor')) {
                resizeTimer = setTimeout(function () {
                    $('#color-swatches .swatchanchor').css('height', 'auto').syncHeight();
                }, 250);
            }
        });

        imagesLoaded('.swatches.color').on('done', function () {
            $('#color-swatches').slick({
                mobileFirst: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                centerMode: false,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: '16px'
                    }
                }]
            });
        });
    });
};

module.exports.loadZoom = loadZoom;
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
module.exports.setupCarousel = setupCarousel;
