'use strict';

var dialog = require('../../dialog'),
	util = require('../../util');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var addToCartPopup = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'addToCartPopup').appendTo(document.body);
        }
    },

    setup: function (qvUrl) {

    },

    show: function (pliuuid, pliqty) {
        timer.clear();
        this.init();
        var url = util.appendParamToURL(Urls.getProductsPopup, 'pliuuid', pliuuid);
        url = util.appendParamToURL(url, 'pliqty', pliqty);
        dialog.open({
			url:  util.ajaxUrl(url),
			options: {
				dialogClass: 'just-added-to-cart',
				modal: false,
                draggable: false,
                resizable: false,
                fluid: true,
                width: 'auto',
                maxWidth: 427
            },
            callback: function () {
                // show hide promo details
                $('.continue-shopping').on('click', function (e) {
					dialog.close();
                });
            }
		});
        timer.start(10000, this.close.bind(this));
    },
	exists: function () {
		return this.$container && (this.$container.length > 0);
	},
    // Close modal
    close: function () {
        dialog.close();
    }
};

$(window).on('featuresSliderLoaded', function () {
	$('#featured-carousel').slick({
		slidesToScroll: 1,
		slidesToShow: 3
	});
});
module.exports = addToCartPopup;
