'use strict';

var compareWidget = require('../compare-widget'),
	productTile = require('../product-tile'),
	progress = require('../progress'),
	util = require('../util'),
	product = require('./product/index'),
    imagesLoaded = require('imagesloaded');

function infiniteScroll() {
	// getting the hidden div, which is the placeholder for the next page
	var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
	// get url hidden in DOM
	var gridUrl = loadingPlaceHolder.attr('data-grid-url');

	if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
		// switch state to 'loading'
		// - switches state, so the above selector is only matching once
		// - shows loading indicator
		loadingPlaceHolder.attr('data-loading-state', 'loading');
		loadingPlaceHolder.addClass('infinite-scroll-loading');

		/**
		 * named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
		 */
		var fillEndlessScrollChunk = function (html) {
			loadingPlaceHolder.removeClass('infinite-scroll-loading');
			loadingPlaceHolder.attr('data-loading-state', 'loaded');
			$('div.search-result-content').append(html);
		};

		// old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
		// it was removed to temporarily address RAP-2649
		if (false) {
			// if we hit the cache
			fillEndlessScrollChunk(sessionStorage['scroll-cache_' + gridUrl]);
		} else {
			// else do query via ajax
			$.ajax({
				type: 'GET',
				dataType: 'html',
				url: gridUrl,
				success: function (response) {
					// put response into cache
					try {
						sessionStorage['scroll-cache_' + gridUrl] = response;
					} catch (e) {
						// nothing to catch in case of out of memory of session storage
						// it will fall back to load via ajax
					}
					// update UI
					fillEndlessScrollChunk(response);
					productTile.init();
				}
			});
		}
	}
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListingFull(url, focusElement) {
    if (!url || url === window.location.href) {
        return;
    }
    progress.show($('.search-result-content'));
    $('#main').load(util.appendParamToURL(url, 'format', 'ajax'), function () {
		compareWidget.init();
		productTile.init();
		progress.hide();
		//housekeeping on the gridPromoSlot
		handleGridPromoSlot(false);
		history.pushState(undefined, '', url);
        if (focusElement && focusElement.attr('id')) {
            document.getElementById(focusElement.attr('id')).focus();
        }
	});
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing (query, pop) {
	var refineUrl;

	if (typeof window.history.pushState === 'function' && typeof query === 'string') {
		if (query && ! pop) {
			refineUrl = window.location.pathname + '?' + query;
			window.history.pushState(query, $(document).find('title').text(), refineUrl);
		} else if (query && pop) {
			refineUrl = window.location.pathname + '?' + query;
		} else {
			refineUrl = window.location.pathname;
		}
	} else {
		var hash = location.href.split('#')[1];
		if (hash === 'results-content' || hash === 'results-products') { return; }

		if (typeof hash !== 'undefined' && hash.length > 0) {
			refineUrl = window.location.pathname + '?' + hash;
		} else {
			refineUrl = window.location.pathname;
		}
	}

	progress.show($('.search-result-content'));

	$('#main').load(util.appendParamToURL(refineUrl, 'format', 'ajax'), function () {
		compareWidget.init();
		productTile.init();
		progress.hide();
		//housekeeping on the gridPromoSlot
		handleGridPromoSlot(false);
	});
}

/**
 * @private
 * @function
 * @param {Bool} If the slot is visible
 * @description housekeeping for the gridPromoSlot
 */
function handleGridPromoSlot(visible) {
	//if (window.innerWidth < 1200) {
	//	visible = false;
	//}
	//on load set height and width, position tile-8
	if (visible) {
		//tall tile
		if ($('.vertical').length > 0) {
			var tileHeight = $('.grid-tile').outerHeight();
			$('.vertical .curated-box').outerHeight(tileHeight * 2);
		}
		//wide or standard
		if ($('.wide-curated, .standard-curated').length > 0) {
		}
	} else {
		//detach the slot, remove tile-8's positioning if tall, do housekeeping on tile height
		$('.tall-curated, .wide-curated, .standard-curated').detach();
	}
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
	var $main = $('#main');

	// add current scroll position to history.state
	$(document).on('click', '.pt_product-search-result .thumb-link, .pt_product-search-result .name-link, .pt_product-search-result .swatch', function () {
		var currentTile = $(this).closest('.product-tile');
		var currentTilePosition = currentTile.offset();
		var currentScrollPosition = document.documentElement.scrollTop;
		var bodyHeight = $('body').height();
		var tileHeight = $('.grid-tile:not(.banner-grid)').eq(0).outerHeight();
		var url = window.location.href;
		url = util.removeParamFromURL(url, 'th');
		url = util.removeParamFromURL(url, 'bh');
		url = util.appendParamsToUrl(url, {'th': tileHeight, 'bh': bodyHeight, 'source': 'pdp'});
		window.history.pushState(
			{
				scrollPosition: currentScrollPosition,
				currentTilePosition: currentTilePosition.top,
				bodyHeight: bodyHeight,
				tileHeight: tileHeight,
				tile2x2Height: (tileHeight * 2 + 32)
			},
			$(document).find('title').text(), url);
	});

	// compare checked
	$main.on('click', 'input[type="checkbox"].compare-check', function () {
		var cb = $(this);
		var tile = cb.closest('.product-tile');

		var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
		var itemImg = tile.find('.product-image a img').first();
		func({
			itemid: tile.data('itemid'),
			uuid: tile[0].id,
			img: itemImg,
			cb: cb
		});

	});

	// handle mobile toggle of refinement menu
	$main.on('click', '.refinement-header-mobile', function () {
		$(this).toggleClass('expanded');
		var innerText = $(this).hasClass('expanded') ? Resources.SEARCH_HIDE + ' <span class=\'arrow-toggle\'></span>' :
            Resources.REFINE_BY + ' <span class=\'arrow-toggle\'></span>';
		$(this).html(innerText);
		// set up width
		var mainWidth = $('#main').width() + 'px';
		$('#secondary').css('width', mainWidth);
		// set up offset
		var topPos = $(this).position().top + $(this).height() + 10;
		$('#secondary').css('top', topPos).toggle();
	});

	// handle toggle refinement blocks
	$main.on('click', '.refinement h3', function () {
		$(this).toggleClass('expanded')
		.siblings('ul, .clear-refinement').toggle();
	});

	// handle events for updating grid
	$main.on('click', '.refinements a, .pagination a, .selected-refinement-value a', function (e) {
		if ($(this).parent().hasClass('unselectable')) { return; }
		var catparent = $(this).parents('.category-refinement');
		var folderparent = $(this).parents('.folder-refinement');

		//if the anchor tag is underneath a div with the class names & , prevent the double encoding of the url
		//else handle the encoding for the url
		if (catparent.length > 0 || folderparent.length > 0) {
			return true;
		} else {
			e.preventDefault();
			updateProductListingFull(this.href, $(this));
		}
	});

	// handle events item click. append params.
	$main.on('click', '.product-tile a:not("#quickviewbutton")', function () {
		var a = $(this);
		// get current page refinement values
		var wl = window.location;

		var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
		var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

		// merge hash params with querystring params
		var params = $.extend(hashParams, qsParams);
		if (!params.start) {
			params.start = 0;
		}
		// get the index of the selected item and save as start parameter
		var tile = a.closest('.product-tile');
		var idx = tile.data('idx') ? + tile.data('idx') : 0;

		// convert params.start to integer and add index
		params.start = (+params.start) + (idx + 1);
		// set the hash and allow normal action to continue
		a[0].hash = $.param(params);
	});

	// handle sorting change
	$main.on('change', '.sort-by select', function () {
		var refineUrl = $(this).find('option:selected').val();
		var uri = util.getUri(refineUrl);

		if (typeof window.history.pushState === 'function') {
			updateProductListing(uri.query.substring(1));
		} else {
			window.location.hash = uri.query.substring(1);
		}

		return false;
	})
	.on('change', '.items-per-page select', function () {
		var refineUrl = $(this).find('option:selected').val();
		if (refineUrl === 'INFINITE_SCROLL') {
			$('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
		} else {
			$('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
			var uri = util.getUri(refineUrl);

			if (typeof window.history.pushState === 'function') {
				updateProductListing(uri.query.substring(1));
			} else {
				window.location.hash = uri.query.substring(1);
				handleGridPromoSlot(false);
			}
		}
		return false;
	});

	// Detect when a user clicks the back button after the pushState function has been called
	if (typeof window.history.pushState === 'function') {
		// Safari fires popstate on load, hack to prevent firing this event on page load
		window.addEventListener('load', function () {
			setTimeout(function () {
				window.addEventListener('popstate', function (event) {
					updateProductListing(event.state, true);
				});
			}, 0);
		});
	}

	// handle toggle clp menu
	$main.on('click', '.sidebar-menu h5', function () {
		$(this).siblings('ul').toggleClass('expanded');
	});
	// Featured carousel
	$('#featured-carousel').slick({
		slidesToScroll: 1,
		slidesToShow: 4,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
	// handle hash change
	window.onhashchange = updateProductListing;
	//re-sync tiles on window resize
	$(window).on('resize', function () {
		if ($('#search-result-items')) {
			handleGridPromoSlot(true);
		}
	});

}

exports.init = function () {
	var urlParams = util.getQueryStringParams(window.location.href);
	compareWidget.init();
	if (SitePreferences.LISTING_INFINITE_SCROLL) {
		$(window).on('scroll', infiniteScroll);
	}
	productTile.init();
	product.init();
	initializeEvents();

	if (urlParams.th === undefined) {
        handleGridPromoSlot(true);
    }

	// If a hash is given update the product grid with the new parameters
	if (window.location.hash) {
		updateProductListing();
	}
	imagesLoaded('.tiles-container').on('always', function () {
		$('body').removeAttr('style');
    });

    // Update Search Queries in Search Bar After Returning Results
    var searchParams = new URLSearchParams(window.location.search);
    var qParam = searchParams ? searchParams.get('q') : null;
    if (qParam) {
        $('input#q').val(qParam);
    }
};
