'use strict';

/**
 * @description Creates product recommendation carousel
 **/
module.exports = function () {
	var $carousel = $('#carousel-recommendations');
	$carousel.slick();
	$('.swatch-list').on('mouseleave', function () {
		// Restore current thumb image
		var $tile = $(this).closest('.product-tile'),
			$thumb = $tile.find('.product-image .thumb-link img').eq(0),
			badgePlp = $tile.children('img').filter(':first'),
			data = $thumb.data('current');

		if (data && data.hasOwnProperty('src')) {
			$thumb.attr({
				src: data.src,
				alt: data.alt,
				title: data.title
			});
		}

		if (typeof(data.bage) != 'undefined') {
			if (badgePlp.length) {
				badgePlp.replaceWith(data.bage);
			} else {
				$tile.prepend(data.bage);
			}
		}
	});
	$('.swatch-list .swatch').on('click touchstart', function (e) {
		e.preventDefault();
		if ($(this).hasClass('selected')) { return; }

		var $tile = $(this).closest('.product-tile');
		$(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
		$(this).addClass('selected');
		$tile.find('.thumb-link').attr('href', $(this).attr('href'));
		$tile.find('.name-link').attr('href', $(this).attr('href'));

		var data = $(this).children('img').filter(':first').data('thumb');
		var bage = $(this).children('img').filter(':first').data('badgeurl');
		var badgePlp = $tile.children('img').filter(':first');
		var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
		var currentAttrs = {
			src: data.src,
			alt: data.alt,
			title: data.title,
			bage: $('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">')
		};
		$thumb.attr(currentAttrs);
		$thumb.data('current', currentAttrs);
		if (typeof(bage) != 'undefined') {
			if (badgePlp.length) {
				badgePlp.prev().remove();
				badgePlp.replaceWith($('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">'));
			} else {
				$tile.find('object').remove()
				$tile.prepend($('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">'));
			}
		} else {
			badgePlp.replaceWith('');
		}
	}).on('mouseenter', function () {
		// get current thumb details
		var $tile = $(this).closest('.product-tile'),
			$thumb = $tile.find('.product-image .thumb-link img').eq(0),
			data = $(this).children('img').filter(':first').data('thumb'),
			bage = $(this).children('img').filter(':first').data('badgeurl'),
			badgePlp = $tile.children('img').filter(':first'),
			current = $thumb.data('current');

		// If this is the first time, then record the current img
		if (!current) {
			$thumb.data('current', {
				src: $thumb[0].src,
				alt: $thumb[0].alt,
				title: $thumb[0].title,
				bage: badgePlp
			});
		} else {
			if (typeof(current.bage) == 'undefined') {
				current.bage = badgePlp;
				$thumb.data('current', current);
			}
		}

		if (typeof(bage) != 'undefined') {
			if (badgePlp.length) {
				badgePlp.prev().remove();
				badgePlp.replaceWith($('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">'));
			} else {
				$tile.find('object').remove()
				$tile.prepend($('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">'));
			}
		} else {
			badgePlp.replaceWith('');
		}
		// Set the tile image to the values provided on the swatch data attributes
		$thumb.attr({
			src: data.src,
			alt: data.alt,
			title: data.title
		});
	});
	$('.swatch.selected').trigger('mouseenter');
};
