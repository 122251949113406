'use strict';

var headerslider = {
    init: function () {
        var headerSlider = $('#header-banner-slot .header-slider');
        if (headerSlider.find('.header-promotion').length > 1) {
            headerSlider.slick({
                speed: 300,
                dots: false,
                arrows: false,
                vertical: true,
                autoplay: true,
                autoplaySpeed: SitePreferences.HEADER_SLIDER_ROTATION_DELAY || 3000,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        }
    }
};

module.exports = headerslider;
