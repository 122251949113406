'use strict';

var dialog = require('./dialog'),
	page = require('./page'),
	util = require('./util');

var selectedList = [];
var maxItems = 1;
var bliUUID = '';

/**
 * @private
 * @function
 * description Gets a list of bonus products related to a promoted product
 */
function getBonusProducts() {
	var o = {};
	o.bonusproducts = [];

	var i, len;
	for (i = 0, len = selectedList.length; i < len; i++) {
		var p = {
			pid: selectedList[i].pid,
			qty: selectedList[i].qty,
			options: {}
		};
		var a, alen, bp = selectedList[i];
		if (bp.options) {
			for (a = 0, alen = bp.options.length; a < alen; a++) {
				var opt = bp.options[a];
				p.options = {optionName:opt.name, optionValue:opt.value};
			}
		}
		o.bonusproducts.push({product:p});
	}
	return o;
}

var selectedItemTemplate = function (data) {
	var attributes = '';
	for (var attrID in data.attributes) {
		var attr = data.attributes[attrID];
		attributes += '<li data-attribute-id="' + attrID + '">\n';
		attributes += '<span class="display-name">' + attr.displayName + '</span>: ';
		attributes += '<span class="display-value">' + attr.displayValue + '</span>\n';
		attributes += '</li>';
	}
	attributes += '<li class="item-qty">\n';
	attributes += '<span class="display-name">' + Resources.QTY + '</span>: ';
	attributes += '<span class="display-value">' + data.qty + '</span>';
	return [
		'<li class="selected-bonus-item" data-uuid="' + data.uuid + '" data-pid="' + data.pid + '">',
		'<a class="remove-link" title="' + Resources.REMOVE + '" href="#"></a>',
		'<div class="item-name">' + data.name + '</div>',
		'<ul class="item-attributes">',
		attributes,
		'<ul>',
		'<li>'
	].join('\n');
};

// hide swatches that are not selected or not part of a Product Variation Group
var hideSwatches = function () {
	$('.must-select').each(function () {
		$(this).find('.header-toggle').html($(this).find('li.selected').html());
		$(this).find('.content').hide();
	});
	$('.must-select li.selected').closest('.must-select').removeClass('.not-selected').addClass('selected');
};

/**
 * @private
 * @function
 * @description Updates the summary page with the selected bonus product
 */
function updateSummary() {
	var $bonusProductList = $('#bonus-product-list');
	if (!selectedList.length) {
		$bonusProductList.find('li.selected-bonus-item').remove();
		$('.add-to-cart-bonus').attr('disabled', 'disabled');
	} else {
		var ulList = $bonusProductList.find('ul.selected-bonus-items').first();
		var i, len;
		for (i = 0, len = selectedList.length; i < len; i++) {
			var item = selectedList[i];
			var li = selectedItemTemplate(item);
			$(ulList).append(li);
		}
		$('.add-to-cart-bonus').removeAttr('disabled');
	}

	// get remaining item count
	var sumItems = 0;
	$('.selected-bonus-item').each(function () {
		sumItems += Number($(this).find('.item-qty .display-value').text());
	});
	var remain = maxItems - sumItems;
	if (remain <= 0) {
		$bonusProductList.find('.select-bonus-item').removeClass('enabled')
			.attr('disabled', 'disabled');
	} else {
		$('.bonus-product-item').each(function () {
			if ($(this).find('.product-variations').length < 1 || !$.isEmptyObject($(this).find('.product-variations').data('attributes'))) {
				$(this).find('.select-bonus-item').addClass('enabled')
					.removeAttr('disabled');
			}
		});
	}
}

var firstLoad = true;

function initializeGrid () {
	var $bonusProduct = $('#bonus-product-dialog'),
		$bonusProductList = $('#bonus-product-list'),
		bliData = $bonusProductList.data('line-item-detail');
		maxItems = bliData.maxItems;
		bliUUID = bliData.uuid;

	$bonusProductList.on('click', '.header', function () {
		var that = this;
		$('.content:visible').slideUp('slow');
		$('.header span.expanded')
			.removeClass('expanded')
			.addClass('collapsed');
		$(this)
			.next('.content:hidden')
			.slideDown('slow', function () {
				$('span.collapsed', that)
					.removeClass('collapsed')
					.addClass('expanded');
			});
	});
	$bonusProductList.on('click', '.product-detail .custom-select li, .bonus-product-item a[href].swatchanchor', function (e) {
		e.preventDefault();
		var url = this.href || this.getAttribute('value'),
			$this = $(this);
		url = util.appendParamsToUrl(url, {
			'source': 'bonus',
			'format': 'ajax'
		});
		$.ajax({
			url: url,
			success: function (response) {
				$this.closest('.bonus-product-item').empty().html(response);
				hideSwatches();
			}
		});
	})
	.on('change', '.input-text', function () {
		var sumItems = 0;
		$('.selected-bonus-item').each(function () {
			sumItems += Number($(this).find('.item-qty .display-value').text());
		});
		var parent = $(this).parents('.bonus-product-form'),
			variations = $(this).parents('.bonus-product-item').find('.product-variations'),
			selectBtn = parent.find('.select-bonus-item');

		if (variations.length > 0 && $.isEmptyObject(variations.data('attributes'))) {
			selectBtn.removeClass('enabled')
			.attr('disabled', 'disabled');
		} else if ($(this).val() <= maxItems - sumItems) {
			selectBtn.addClass('enabled')
				.removeAttr('disabled');
			parent.find('.quantity-error').text('');
			$(this).removeClass('error');
		} else {
			selectBtn.removeClass('enabled')
				.attr('disabled', 'disabled');
			$(this).addClass('error');
			parent.find('.quantity-error').text(Resources.BONUS_PRODUCT_TOOMANY);
		}
	})
	.on('change keyup input click', '.input-text', function () {
		if (this.value.match(/[^0-9]/g)) {
			this.value = this.value.replace(/[^0-9]/g, '');
		}
    })
	.on('click', '.select-bonus-item', function (e) {
		e.preventDefault();
		var sumItems = 0;
		$('.selected-bonus-item').each(function () {
			sumItems += Number($(this).find('.item-qty .display-value').text());
		});
		if (($(this).hasClass('disabled') || $(this).is(':disabled')) && !$(this).hasClass('enabled')) {
			var $unselectedVariants = $(this).closest('.bonus-product-item').find('.must-select:not(.selected)');
			if ($unselectedVariants.length > 0) {
				$unselectedVariants.addClass('not-selected');
				$('.selection-error').show();
			}
			return false;
		}
		if (selectedList.length >= maxItems) {
			$bonusProductList.find('.select-bonus-item').removeClass('enabled').attr('disabled', 'disabled');
			return;
		}

		var form = $(this).closest('.bonus-product-form'),
			detail = $(this).closest('.product-detail'),
			uuid = form.find('input[name="productUUID"]').val(),
			qtyVal = form.find('input[name="Quantity"]').val(),
			qty = (isNaN(qtyVal)) ? 1 : (+qtyVal);

		if (qty > maxItems - sumItems) {
			$(this).attr('disabled', 'disabled');
			form.find('.quantity-error').text(Resources.BONUS_PRODUCT_TOOMANY);
			return;
		}
		var product = {
			uuid: uuid,
			pid: form.find('input[name="pid"]').val(),
			qty: qty,
			name: detail.find('.product-name').text(),
			attributes: detail.find('.product-variations').data('attributes'),
			options: []
		};

		var optionSelects = form.find('.product-option');

		optionSelects.each(function () {
			product.options.push({
				name: this.name,
				value: $(this).val(),
				display: $(this).children(':selected').first().html()
			});
		});
		selectedList.push(product);
		$('#bonus-product-list li').remove('.selected-bonus-item');
		updateSummary();
	})
	.on('click', '.remove-link', function (e) {
		e.preventDefault();
		var container = $(this).closest('.selected-bonus-item');
		if (!container.data('uuid')) { return; }

		var uuid = container.data('uuid');
		var i, len = selectedList.length;
		for (i = 0; i < len; i++) {
			if (selectedList[i].uuid === uuid) {
				selectedList.splice(i, 1);
				break;
			}
		}
		$('#bonus-product-list li').remove('.selected-bonus-item');
		updateSummary();
	})
	.on('click', '.add-to-cart-bonus', function (e) {
		e.preventDefault();

		var url = util.appendParamsToUrl(Urls.addBonusProduct, {bonusDiscountLineItemUUID: bliUUID});
		var bonusProducts = getBonusProducts();
		if (bonusProducts.bonusproducts[0].product.qty > maxItems) {
			bonusProducts.bonusproducts[0].product.qty = maxItems;
		}
		// make the server call
		$.ajax({
			type: 'POST',
			dataType: 'json',
			cache: false,
			contentType: 'application/json',
			url: url,
			data: JSON.stringify(bonusProducts)
		})
		.done(function () {
			// success
			page.redirect(Urls.cartShow);
		})
		.fail(function (xhr, textStatus) {
			// failed
			if (textStatus === 'parsererror') {
				window.alert(Resources.BAD_RESPONSE);
			} else {
				window.alert(Resources.SERVER_CONNECTION_ERROR);
			}
		})
		.always(function () {
			$bonusProduct.dialog('close');
		});
	});
	if (firstLoad) {
		var cartItems = $bonusProductList.find('.selected-bonus-item');
		cartItems.each(function () {
			var selectedItem = '#item-' + $(this).data('uuid');
			var selectedItemQty = $(this).find('.selected-qty').text();
			$(this).remove();
			$(selectedItem).find('.bonus-product-form .input-text').val(selectedItemQty);
			$(selectedItem).find('.bonus-product-form .select-bonus-item').trigger('click');
		});
		firstLoad = false;
	}
	var sumItems = 0;
	$('.selected-bonus-item').each(function () {
		sumItems += Number($(this).find('.item-qty .display-value').text());
	});

	if (sumItems >= maxItems) {
		$bonusProductList.find('.select-bonus-item').removeClass('enabled')
			.attr('disabled', 'disabled');
	}
}

var bonusProductsView = {
	/**
	 * @function
	 * @description Open the list of bonus products selection dialog
	 */
	show: function (url) {
		var $bonusProduct = $('#bonus-product-dialog');
		// create the dialog
		dialog.open({
			target: $bonusProduct,
			url: url,
			options: {
				width: 385,
				title: Resources.BONUS_PRODUCT,
				dialogClass: 'bonus-dialog',
				open: function () {
					initializeGrid();
					hideSwatches();
				}
			}
		});
	},
	/**
	 * @function
	 * @description Open bonus product promo prompt dialog
	 */
	loadBonusOption: function () {
		var	self = this,
			bonusDiscountContainer = document.querySelector('.bonus-discount-container');
		if (!bonusDiscountContainer) { return; }

		// get the html from minicart, then trash it
		var bonusDiscountContainerHtml = bonusDiscountContainer.outerHTML;
		bonusDiscountContainer.parentNode.removeChild(bonusDiscountContainer);
		setTimeout(function () {
			dialog.open({
				html: bonusDiscountContainerHtml,
				options: {
					width: 385,
					title: Resources.BONUS_PRODUCT,
					dialogClass: 'bonus-dialog',
					buttons: [{
						text: Resources.SELECT_BONUS_PRODUCTS,
						click: function () {
							var uuid = $('.bonus-product-promo').data('lineitemid'),
								url = util.appendParamsToUrl(Urls.getBonusProducts, {
									bonusDiscountLineItemUUID: uuid,
									source: 'bonus'
								});
							$(this).dialog('close');
							self.show(url);
						}
					}, {
						text: Resources.NO_THANKS,
						click: function () {
							$(this).dialog('close');
						}
					}]
				},
				callback: function () {
					// show hide promo details
					$('.show-promo-details').on('click', function () {
						$('.promo-details').toggleClass('visible');
					});
				}
			});
		}, 1000);
	}
};

module.exports = bonusProductsView;
