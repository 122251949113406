'use strict';

var util = require('./util');

var toggleAriaExpanded = function (el) {
	var currValue = $(el).attr('aria-expanded');
	if (currValue == 'true') {
		currValue = 'false';
	} else {
		currValue = 'true';
	}
	$(el).attr('aria-expanded', currValue);
}

var toogleMainMenu = function () {
	$(this).toggleClass('open');
	$('#wrapper').toggleClass('menu-active');
	$('#navigation li').removeClass('active');
	toggleAriaExpanded(this);
};

var toggleSearchMenu = function () {
	var $headerSearch = $(this).parents('.header-search');
	$headerSearch.toggleClass('opened');
	if ($headerSearch.hasClass('opened')) {
		$headerSearch.find('#q').focus();
	}
};

var headermenu = {
	init: function () {
		// main menu toggle
		$('.menu-toggle').on('click', function () {
			toogleMainMenu.apply(this);
		}).on('keydown', function (e) {
			var key = e.keyCode ? e.keyCode : e.which;
			var KEY_CODE_ENTER = 13;
			if (KEY_CODE_ENTER == key) {
				toogleMainMenu.apply(this);
			}
		});

		$('.header-search-btn').on('click', function () {
			toggleSearchMenu.apply(this);
		});

        var expandSubMenu = function (e) {
            e.preventDefault();
            if ($(this).is('.menu-item-toggle')) {
                // Ctrl-Click main categories open in new browser
                if ($(this).hasClass('active') || window.innerWidth > 1199) {
                    if (e.ctrlKey) {
                        window.open($(this).attr('href'), '_blank');
                    } else {
                        document.location.href = $(this).attr('href');
                        return;
                    }
                }
            }
            var isLinkClicked = (e.pageX - $(this).position().left) < ($(this).width() - 50);
			var $parentLi = $(e.target).closest('li');
			$parentLi.siblings('li').removeClass('active');
			$parentLi.toggleClass('active');
			toggleAriaExpanded(this);
        };

		var onHover = function (e) {
			if (window.innerWidth < 1200) {
				return;
			}
			$('.level-2').delay(300).hide(0);
			var $this = $(this).hasClass('menu-item-toggle') ? $(this).parent('li') : $(this);
			var firstFocusable = $this.find('a').first()[0];
			// check if focus is in current area, if not trigger focus on first element
			// trigger only on usual hover, if focus is out of object
			if (e.type != 'focusin' && firstFocusable && (!document.activeElement || !$.contains($this[0], document.activeElement))) {
				firstFocusable.needFocus = true;
				firstFocusable.focus();
				// added check and return after focus triggered, it can fail
				if (!firstFocusable.needFocus) return;
			}
			// set flag to mark focus
			this.needFocus = false;
			var $elements = $this.find('.megamenu-wrapper').children('ul, div');
			$this.find('.level-2')
				.stop(true, true)
				.delay(150)
				.queue(function (next) {
					if ($this.hasClass('open') === false) {
						// set opacity of content inside menu to 0 before recursiveReveal()
						$elements.css('opacity', '0');
						next();
					}
				})
				.slideDown(function () {
					if ($this.hasClass('open') === false) {
						$this.addClass('active open');
						util.recursiveReveal($elements);
					}
				});
		};
		var unHover = function (e) {
			// prevent unhover if we are no moving from that tab
			if ($(e.relatedTarget).parents('li.open').length > 0) {
				return;
			}
			var $this = $(this).hasClass('menu-item-toggle') ? $(this).parent('li') : $(this);
			$this.find('.level-2')
				.stop(true, true)
				.delay(200)
				.fadeOut('fast', function () {
					$this.removeClass('active open');
				});
		};
		var keyPress = function (e) {
			// prevent unhover if we are no moving from that tab
			var key = e.keyCode ? e.keyCode : e.which,
				KEY_CODE_SPACE = 32;
			if (KEY_CODE_SPACE == key) {
				e.preventDefault();
				if (!$(this).parents('li').hasClass('open')) {
					$(this).trigger('mouseenter');
				} else {
					$(this).trigger('mouseleave');
				}
			}
		};

		$('.top-category-name').on('click', function () {
			$(this).closest('li').removeClass('active');
		});

		$('.menu-category li .menu-item-toggle, .menu-category li .menu-item-toggle-nav').on('click', expandSubMenu);

		if (window.innerWidth > 1199) {
			$('.menu-category.level-1').children('li').hover(onHover, unHover);
			$('.menu-category li .menu-item-toggle').on('keydown', keyPress);
		}
		var resizeTimeout;
		function resizeThrottler () {
			if (!resizeTimeout) {
				resizeTimeout = setTimeout(function () {
					resizeTimeout = null;
					resizeHandler();
				}, 400);
			}
		}

		function resizeHandler () {
			if (window.innerWidth < 1200) {
				$('.mobile-always-opened .level-2').show();
			}
		}

		window.addEventListener('resize', resizeThrottler, false);
	}
};

module.exports = headermenu;
