'use strict';
var producttiles = require('../product-tile');

function initGridSlots() {
    var tileHeight = $('.grid-tile:not(.banner-grid):first').height();
    var tileMargin = $('.grid-tile:not(.banner-grid):first').outerHeight(true) - tileHeight;
    $('.banner-grid.grid-tile1x1').height(tileHeight);
    $('.banner-grid.grid-tile2x1').height(tileHeight);
    $('.banner-grid.grid-tile2x2').height(tileHeight * 2 + tileMargin);
}
exports.init = function () {
    //top of homepage carousel
    var $storefront = $('.pt_storefront.home');
    var setCookie = function () {
        var nextMonth = new Date();
        nextMonth.setDate(nextMonth.getDate() + 30);
        var expiresDate = nextMonth.toString();
        document.cookie = SitePreferences.GATED_COOKIE_NAME + '=' + SitePreferences.GATED_COOKIE_VALUE + '; path=/; expires=' + expiresDate;
    };

    if (window.location.search.indexOf('q=byPass') > -1) {
        setCookie();
    }
    producttiles.init();
    initGridSlots();

    $('.button-play-video').on('click', function (e) {
        e.preventDefault();
        var $targetVideo = $('#' + $(this).data('target-video'));
        var $videoPlayer = $(this).parents('.video-player');
        if ($(this).is('.button-pause')) {
            $targetVideo.get(0).pause();
            $videoPlayer.removeClass('video-play');
        } else {
            $targetVideo.get(0).play();
            $videoPlayer.addClass('video-play');
        }
    });

    function smoothScroll(target) {
        var scrollCorrection = 0;
        if ($('#top-banner.sticky')) {
            scrollCorrection = $('#top-banner.sticky').height() - 1;
        }
        $('body,html').animate(
            {'scrollTop': target.offset().top - scrollCorrection}, 600
        );
    }
    $storefront.on('click', '#right-side-nav a', function (e) {
        e.preventDefault();
        $('#right-side-nav li').removeClass('current');
        $(this).parent().addClass('current');
        smoothScroll($(this.hash));
    });

    $(window).on('scroll', function () {
        if ($('.pt_storefront').length && $('.fj1857-home-slot-5.light-background').length) {
            var $lightSection = $('.fj1857-home-slot-5.light-background'),
                $scrollNav = $('#right-side-nav'),
                $scrollDots = $('#right-side-nav li'),
                sectionTop = $lightSection.offset().top,
                sectionBottom = sectionTop + $lightSection.outerHeight();

            $scrollDots.each(function () {
                var scrollDotsTop = $(this).offset().top,
                    scrollDotsBottom = scrollDotsTop + $(this).height();
                if ((scrollDotsBottom >= sectionTop && scrollDotsBottom <= sectionBottom) || (scrollDotsTop <= sectionBottom && scrollDotsTop >= sectionTop)) {
                    $(this).addClass('dark-style');
                } else {
                    $(this).removeClass('dark-style');
                }
            });

            var $slotSections = $('.pt_storefront #main > section');
            $slotSections.each(function () {
                var slotTop = $(this).offset().top,
                    slotBottom = slotTop + $(this).outerHeight(),
                    scrollNanCenter = $scrollNav.offset().top + $scrollNav.height() / 2,
                    windowScroll = $(window).scrollTop();
                if (slotTop >= windowScroll && slotTop < scrollNanCenter) {
                    var targetDotId = $(this).find('.home-slot-content').data('nav-item');
                    $scrollNav.find('li').removeClass('current');
                    $('#' + targetDotId).addClass('current');
                }
            });

            var topDistance = '';
            if ($('.fj1857-home-slot-5.light-background').length) {
                topDistance = $('.fj1857-home-slot-5.light-background').offset().top;
            }
            if (topDistance) {
                var scrollTop = $(this).scrollTop() + $('.top-banner').height() + 35;
                if ((topDistance) < parseInt(scrollTop)) {
                    $('.top-banner').addClass('theme--light');
                } else {
                    $('.top-banner').removeClass('theme--light');
                }
            }
        }
    });

    $('#home-slot-4 .slider-container').slick({
        slidesToScroll: 1,
        slidesToShow: 1,
        arrows: true,
        dots: false,
        infinite: true,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        variableWidth: true,
        centerPadding: '0px',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: false
                }
            }
        ]
    });

    $(document).ready(function () {
        if ($('.pt_storefront.home').length) {
            var $activeSlots = $('.home-slot-content');
            $activeSlots.each(function (index) {
                $(this).data('nav-item', 'item-' + index);
                var slotLink = $(this).data('slot-id');
                $('<li id="item-' + index + '"><a href=#' + slotLink + '></a></li>').appendTo($('#right-side-nav ul'));
            });
        }
    });
};
