'use strict';

var imagesLoaded = require('imagesloaded'),
	util = require('./util'),
	quickview = require('./quickview');

function initQuickViewButtons() {
	$('.tiles-container .product-image').on('mouseenter', function () {
		if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW) {
			return;
		}
		var $qvButton = $('#quickviewbutton');
		if ($qvButton.length === 0) {
			$qvButton = $('<button id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '<i class="fa fa-arrows-alt"></i></button>');
		}
		var $link = $(this).find('.thumb-link');
		$qvButton.attr({
			'href': $link.attr('href'),
			'title': $link.attr('title')
		}).appendTo(this);
		$qvButton.on('click', function (e) {
			e.preventDefault();
			quickview.show({
				url: $(this).attr('href'),
				source: 'quickview'
			});
		});
	});
}

function gridViewToggle() {
	$('.toggle-grid').on('click', function () {
		$('.search-result-content').toggleClass('wide-tiles');
		$(this).toggleClass('wide');
	});
}

function swatchImageCkick() {
	$('.swatch-list').on('mouseleave', function () {
		// Restore current thumb image
		var $tile = $(this).closest('.product-tile'),
			$thumb = $tile.find('.product-image .thumb-link img').eq(0),
			data = $thumb.data('current');

		if (data && data.hasOwnProperty('src')) {
			$thumb.attr({
				src: data.src,
				alt: data.alt,
				title: data.title
			});
		}
	});
	$('.swatch-list .swatch').on('click', function (e) {
		e.preventDefault();
		if ($(this).hasClass('selected')) { return; }

		var $tile = $(this).closest('.product-tile');
		$(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
		$(this).addClass('selected');
		$tile.find('.thumb-link').attr('href', $(this).attr('href'));
		$tile.find('.name-link').attr('href', $(this).attr('href'));

		var data = $(this).children('img').filter(':first').data('thumb');
		var $gridTile = $(this).closest('.grid-tile');
		$gridTile.find('.name-link').text(data.productName);

		$.ajax({
			dataType: 'html',
			url: Urls.getProductTilePrice,
			data: 'pid=' + data.productID,
			xhrFields: {
				withCredentials: true
			},
			success: function (data) {
				$gridTile.find('.product-pricing').empty().html(data);
			}
		});

		var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
		var currentAttrs = {
			src: data.src,
			alt: data.alt,
			title: data.title
		};
		$thumb.attr(currentAttrs);
		$thumb.data('current', currentAttrs);
	}).on('mouseenter', function () {
		// get current thumb details
		var $tile = $(this).closest('.product-tile'),
			$thumb = $tile.find('.product-image .thumb-link img').eq(0),
			data = $(this).children('img').filter(':first').data('thumb'),
			current = $thumb.data('current');

		// If this is the first time, then record the current img
		if (!current) {
			$thumb.data('current', {
				src: $thumb[0].src,
				alt: $thumb[0].alt,
				title: $thumb[0].title
			});
		}

		// Set the tile image to the values provided on the swatch data attributes
		$thumb.attr({
			src: data.src,
			alt: data.alt,
			title: data.title
		});
	});
}

/**
 * @private
 * @function
 * @description Toggles full swatch visibility on product tile, as default is collapsed, only showing 4 swatches max
 */
function showMoreSwatch() {
	$('.product-swatches').off('click', 'ul li.open-close');
	$('.product-swatches').on('click', 'ul li.open-close', function (e) {
		e.preventDefault();
		//$(this).parents('.product-swatches').addClass('expanded').find('li.hide, li.hide-mobile').show();
		$(this).parents('.product-swatches').toggleClass('expanded');
	});
}

function initUnselectedSwatches() {
	$('.swatch-list').each(function () {
		if ($(this).find('.swatch').length > 0 && $(this).find('.swatch.selected').length === 0) {
			$(this).find('.swatch').eq(0).trigger('click');
		}
	});
}

function productTileHover() {
    var $searchResults = $('.pt_product-search-result');
    $searchResults.on('mouseenter mouseleave', '.thumb-link', function () {
        var secondaryImg = $(this).data('secondary-image'),
			mainImage = $(this).find('img').attr('src');
        if (!secondaryImg) { return; }
        $(this).data('secondary-image', mainImage);
        $(this).find('img').attr('src', secondaryImg);
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
	initQuickViewButtons();
	gridViewToggle();
	showMoreSwatch();
	swatchImageCkick();
	initUnselectedSwatches();
	productTileHover();
}

exports.init = function () {
	var $tiles = $('.tiles-container .product-tile');
	if ($tiles.length === 0) { return; }
	initializeEvents();
};
module.exports.showMoreSwatch = showMoreSwatch;
module.exports.swatchImageCkick = swatchImageCkick;

