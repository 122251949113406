'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    customize = require('./customize'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    recommendations = require('./recommendations'),
    variant = require('./variant');


/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom () {
    productNav();
    recommendations();
    tooltip.init();
}
function showMoreSwatchPDP () {
    $('.product-swatches').off('click', 'ul li.open-close');
    $('.product-swatches').on('click', 'ul li.open-close', function (e) {
        e.preventDefault();
        $(this).parents('.product-swatches').toggleClass('expanded');
    });
}

// product content slot horizonal-carousels
// added to window for CQuotient, self-executing for fallback
(window.pdpRecommendationsInit = function () {
    var $hCarousel = $('.product-listing-1x4 .search-result-items');
    if ($hCarousel.length == 0) {
        return;
    }
    // do not initialize twice
    if ($hCarousel.hasClass('slick-slider')) {
        return;
    }
    $hCarousel.slick({
        slidesToScroll: 2,
        slidesToShow: 4,
        swipeToSlide: true,
        prevArrow: $(this).parents('.fj-rp-slider-wrapper').find('.fj-rp-slider-arrow.left'),
        nextArrow: $(this).parents('.fj-rp-slider-wrapper').find('.fj-rp-slider-arrow.right'),
        slide: '.grid-tile',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '20px'
                }
            }
        ]
    });
    // bind show more functionality
    showMoreSwatchPDP();
})();

function stickyElementsInit () {
    var $mainImage = $('.main-image');
    var $thumbnails = $('.thumb');
    var $winWidth = 1.5;
    if ($(window).width() > 1200) {
        $winWidth = .8;
    }
    if (util.getViewport() >= 768 && $('#pdpMain').length) {
        var $detailsSection = $('.primary-container'),
            headerHeight = $('#top-banner').height(),
            tmbRight = ($(window).width() - $('#pdpMain').width()) / 2,
            addCols = $('.additional-colors').height() + 50;
        if (($(document).scrollTop() + headerHeight) >= $('.product-col-1').offset().top) {
            if (($('.product-col-2').offset().top + $('.product-col-2').height()) <= ($('.product-col-1').offset().top + $('.product-col-1').height())) {
                $detailsSection.find('.product-col-2').css('opacity', 1);
                $detailsSection.find('#thumbnails').css('opacity', 1);
                if (!$detailsSection.find('.product-col-2').hasClass('fixed-position')) {
                    $detailsSection.find('.product-col-2').addClass('fixed-position');
                    $detailsSection.find('#thumbnails').addClass('fixed-position').css('right', tmbRight);
                }
            }
            if ($(window).scrollTop() + $('.product-col-2').outerHeight() + 200 > $('.footer-wrapper').offset().top - $('.additional-colors').outerHeight()) {
                $detailsSection.find('.product-col-2').addClass('fixed-bottom').css('bottom', addCols + 'px');
            } else {
                $detailsSection.find('.product-col-2').removeClass('fixed-bottom').css('bottom', 'auto');
            }
            if ($(window).scrollTop() + $('#thumbnails .slick-track').height() + 160 > $('.footer-wrapper').offset().top - $('.additional-colors').outerHeight()) {
                $detailsSection.find('#thumbnails').addClass('fixed-bottom').css({'right':'0px', 'height':$('#thumbnails .slick-track').height()});
            } else {
                $detailsSection.find('#thumbnails').removeClass('fixed-bottom').css('right', tmbRight);
            }

        } else {
            if ($detailsSection.find('.product-col-2').hasClass('fixed-position')) {
                $detailsSection.find('.product-col-2').removeClass('fixed-position').css('opacity', 1);
                $detailsSection.find('#thumbnails').removeClass('fixed-position').css('right', 'auto').css('opacity', 1);
            }
        }

        $mainImage.each(function (index) {
            if (($(document).scrollTop() + $(window).height() / $winWidth) >= $(this).offset().top + $(this).height() && $(document).scrollTop() <= ($(this).offset().top + $(this).height() + $(this).height())) {
                $thumbnails.removeClass('selected');
                $($thumbnails[index]).addClass('selected');
            }
        });
    }
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents () {
    var $pdpMain = $('#pdpMain');

    addToCart();
    customize();
    availability();
    variant();
    image();
    productSet();

    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });
    $pdpMain.on('click', '.pr-snippet-stars, .pr-snippet-review-count', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('.reviews-container').offset().top
        }, 1000);
    });
    $pdpMain.on('click', '.js-show-colors', function (e) {
        e.preventDefault();
        var swatches = $(this).parent().find('.selectable');
        var hiddenSwatches = $(this).parent().find('.selectable.hide');
        var buttonText = '';
        if (hiddenSwatches.length) {
            hiddenSwatches.removeClass('hide');
            buttonText = $(this).data('text-less');
            $(this).text(buttonText);
        } else {
            var i = 0;
            swatches.each(function () {
                if (i > 7) {
                    $(this).addClass('hide');
                }
                i++;
            });
            buttonText = $(this).data('text-more');
            $(this).text(buttonText);
        }
    });
    $pdpMain.on('click', '#continue-shopping-oos', function (e) {
        e.preventDefault()
        var categoryLink = $('.breadcrumb .breadcrumb-element').last();
        categoryLink[0].click();
    });

    $('.pt_product-details').on('click', '.tabs-caption li:not(.active)', function () {
        $(this)
            .addClass('active').siblings().removeClass('active')
            .closest('.pdp-description').find('.tabs-content').removeClass('active').eq($(this).index()).addClass('active');
    });
    $('#pdp-gloves-carousel').slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 7000,
        cssEase: 'linear',
        speed: 300
    });

    $('.pt_product-details').on('click', '.scroll-to-button', function (e) {
        e.preventDefault();
        var targetSection = $(this).attr('href');
        var topBannerHeight = $('.top-banner').height();
        var scrollCorrection = topBannerHeight;

        if (util.isMobile()) {
            if (targetSection == '#primary-container') {
                targetSection = '.product-col-2';
                scrollCorrection = topBannerHeight + 50;
            }
        }
        $('body,html').animate(
            {'scrollTop': $(targetSection).offset().top - scrollCorrection}, 600
        );
    });

    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                width: 768,
                dialogClass: 'dialog-fit-guide',
                closeOnEscape: true,
                resizable: false,
                draggable: false,
                modal: true,
                position: {
                    my: 'center top',
                    at: 'center top+100',
                    of: window,
                    collision: 'none'
                }
            }
        });
    });
    $('#free-returns').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });
    $('#pdpMain').on('click', '.modal-link', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                dialogClass: 'dialog-lk-personalization no-close',
                width: 1024,
                position: {
                    my: 'center top',
                    at: 'center top+20',
                    of: window,
                    collision: 'none'
                },
                open: function () {
                    $('.dialog-close-btn').on('click', function (e) {
                        e.preventDefault();
                        dialog.close();
                    });
                }
            }
        });
    });
    $('.pdpLeftCarousel ul').slick();
    $('.pdpPlayers ul').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    });
    showMoreSwatchPDP();
    if (!$('#pdpMain').closest("#QuickViewDialog").length) {
        var searchPage = $('.pt_product-search-result');
		if (searchPage.length === 0) {
			$('html, body').animate({scrollTop: 0}, 'slow');
		}
    }

    $(window).on('scroll', function () {
        stickyElementsInit();
    });
    $(window).on('resize', function () {
        stickyElementsInit();
    });
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
    }
};

module.exports = product;
