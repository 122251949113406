/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */
'use strict';

var countries = require('./countries'),
	cq = require('./cq'),
	dialog = require('./dialog'),
	minicart = require('./minicart'),
	page = require('./page'),
	rating = require('./rating'),
	searchplaceholder = require('./searchplaceholder'),
	searchsuggest = require('./searchsuggest'),
	searchsuggestbeta = require('./searchsuggest-beta'),
    headermenu = require('./headermenu'),
    headerslider = require('./headerslider'),
    headersearch = require('./headersearch'),
	tooltip = require('./tooltip'),
	util = require('./util'),
	product = require('./pages/product/index'),
	customdialog = require('./customdialog'),
	validator = require('./validator'),
	gateway = require('./gateway'),
	contactUs = require('./contact-us');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
	var s = document.createElement('script');
	s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js');
	s.setAttribute('type', 'text/javascript');
	document.getElementsByTagName('head')[0].appendChild(s);
}
require('./jquery-ext')();
require('./cookieprivacy')();

//initialize YouTube API
require('./youtube')();

function initializeEvents() {
	dataLayer.push({'event': 'optimize.activate'});
	var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];
	$('body')
		.on('keydown', 'textarea[data-character-limit]', function (e) {
			var text = $.trim($(this).val()),
				charsLimit = $(this).data('character-limit'),
				charsUsed = text.length;

			if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
				e.preventDefault();
			}
		})
		.on('click', '.ui-widget-overlay', function (e) {
			e.preventDefault();
			dialog.close();
		})
		.on('change keyup mouseup', 'textarea[data-character-limit]', function () {
			var text = $.trim($(this).val()),
				charsLimit = $(this).data('character-limit'),
				charsUsed = text.length,
				charsRemain = charsLimit - charsUsed;

			if (charsRemain < 0) {
				$(this).val(text.slice(0, charsRemain));
				charsRemain = 0;
			}

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        }).on('touchstart', function () {
            $(this).addClass('touch');
	});

    if (util.isMobile()) {
        $('body').addClass('touch');
    }

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
        $(this).toggleClass('open');
    });

	// add generic toggle functionality
	$('.toggle').next('.toggle-content').hide();
	$('.toggle:not(.refinement h3)').click(function () {
		$(this).toggleClass('expanded').next('.toggle-content').toggle();
	});

	// subscribe email box
	var $subscribeEmail = $('.subscribe-email');
	if ($subscribeEmail.length > 0) {
		$subscribeEmail.focus(function () {
			var val = $(this.val());
			if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
				return; // do not animate when contains non-default value
			}

			$(this).animate({
				color: '#999999'
			}, 500, 'linear', function () {
				$(this).val('').css('color', '#333333');
			});
		}).blur(function () {
			var val = $.trim($(this.val()));
			if (val.length > 0) {
				return; // do not animate when contains value
			}
			$(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
				.css('color', '#999999')
				.animate({
					color: '#333333'
				}, 500, 'linear');
		});
	}

    $('.email-alert-signup-popup').on('submit', function (e) {
        e.preventDefault();
        var emailForm = $(this);

        if (emailForm.valid() === false) {
            return false;
        }
        var submitButton = emailForm.find('button[type="submit"]');
        var emailField = emailForm.find('.input-text').first();
        var email = emailField.val();
        var successMessage = $('#footer-signup-ty');

        $.get('/ajax/site-intercept/intercept-manager.aspx?e=' + email, function (data) {
            data = JSON.parse(data);
            if ('status_code' in data) {
                if (data.status_code === 200) {
                    emailField.hide();
                    submitButton.hide();
                    successMessage.show();
                } else {
                    $('#footer-signup-error').html(data.message).show();
                }
            } else {
                $('#footer-signup-error').html('internal error').show();
            }
        });
    });

	$('#term, .privacy-policy, .save-card').on('click', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				height: 600,
				open: function () {
					$('#dialog-container').animate({
						scrollTop: 0
					}, '100');
				}
			}
		});
	});

	// this is to add the 'theme--light' to the sticky menu for PLP and PDP
	$(window).on('scroll', function () {
		if (util.getViewport() >= 768) {
			var topDistance = '';
			if ($('.pt_product-search-result .primary-content').length) {
				topDistance = $('.pt_product-search-result .primary-content').offset().top;
			}
			if ($('#primary-container.primary-container').length) {
				topDistance = $('#primary-container.primary-container').offset().top;
			}
			if (topDistance) {
				var scrollTop = $(this).scrollTop() + $('.top-banner').height() + 35;
				if ((topDistance) < parseInt(scrollTop)) {
					$('.top-banner').addClass('theme--light');
				} else {
					$('.top-banner').removeClass('theme--light');
				}
			}
		}
	});

	// print handler
	$('.print-page').on('click', function () {
		window.print();
		return false;
	});
	// Change Countries
    $(document).on('click', '.countries-container > a', function (e) {
		e.preventDefault();
		$(this).next().toggle();
	});
    $(document).on('click', '.close-countries', function (e) {
		e.preventDefault();
		$('.country-container').hide();
	});
	if ($('.intersitial-message-wrapper').length) {
		dialog.open({
			html: $('.intersitial-message-wrapper').html(),
			options: {
				dialogClass: 'no-close intersitial-message-modal',
				width: 900
			}
		});
		$('.show-countries').on('click', function () {
			dialog.close();
			if ($('.countries-container > a').length > 0) {
				$('.countries-container > a').click();
				$('html, body').animate({
					scrollTop: $('.countries-container > a').offset().top
				}, 1000);
			} else {
				$('.countries-container .icon-expand').click();
				$('html, body').animate({
					scrollTop: $('.countries-container .icon-expand').offset().top
				}, 1000);
			}
		});
	}
	//Show header bottom menu
	var bannerCookieId = $('#header-bottom-banner').data('cookie-id');
	if (document.cookie.indexOf(bannerCookieId) < 0 && $('#header-bottom-banner .header-bottom-banner-content').children().length > 0) {
		$('#header-bottom-banner').removeClass('visually-hidden');
	}
	$('#header-bottom-banner .banner-close-btn').on('click', function () {
		$('#header-bottom-banner').addClass('visually-hidden');
		document.cookie = bannerCookieId + '=1; path=/';
	});
	// Footer Return
	$('.footer-item h3').on('click', function (e) {
		e.preventDefault();
		$(this).closest('.footer-item').toggleClass('active');
	});
	$('.footer-return span').on('click', function () {
		$('html, body').animate({
			scrollTop: 0
		}, 1000);
	});

	$('.menu-utility-user .user-info').on('mouseenter', function () {
		$(this).addClass('active');
	});
	$('.menu-utility-user .user-info').on('mouseleave', function () {
		$(this).removeClass('active');
	});

	$('#help >a').on('click', function (e) {
		e.preventDefault();
	});
	$('.menu-utility-user #help').on('mouseenter', function () {
		$(this).addClass('active');
	});
	$('.menu-utility-user #help').on('mouseleave', function () {
		$(this).removeClass('active');
	});
	//Titleist menu utility
	$('.menu-utility-user.titliest > #search-cell > a').on('click', function (e) {
		e.preventDefault();
		$(this).parent().toggleClass('is-open');
	});
	//Titleist forms in assets

	$('#searchForPlayer button[type="submit"]').click(function (e) {
		$('#searchForPlayer, #zipFindGolfShop, #searchHeaderSubmit').off();
		var input = $(this).siblings('input');
		if (!input.val() && !input.hasClass('zip-error')) {
			e.preventDefault();
			input.addClass('zip-error').after('<span class="zip-error">Enter a player\'s name</span>');
		} else if (!input.val() && input.hasClass('zip-error')) {
			e.preventDefault();
		} else if (input.val()) {
			$('#searchForPlayer').submit();
		}
	});
	$('#zipFindGolfShop button[type="submit"]').click(function (e) {
		$('#searchForPlayer, #zipFindGolfShop, #searchHeaderSubmit').off();
		var input = $(this).siblings('input');
		if (!input.val() && !input.hasClass('zip-error')) {
			e.preventDefault();
			input.addClass('zip-error').after('<span class="zip-error">Please enter an address</span>');
		} else if (!input.val() && input.hasClass('zip-error')) {
			e.preventDefault();
		} else if (input.val()) {
			$('#zipFindGolfShop').submit();
		}
	});
	$('#searchHeaderSubmit button[type="submit"]').click(function (e) {
		$('#searchForPlayer, #zipFindGolfShop, #searchHeaderSubmit').off();
		var input = $(this).siblings('input');
		if (!input.val() && !input.hasClass('zip-error')) {
			e.preventDefault();
			input.addClass('zip-error').after('<span class="zip-error">Please enter a search term</span>');
		} else if (!input.val() && input.hasClass('zip-error')) {
			e.preventDefault();
		} else if (input.val()) {
			$('#searchHeaderSubmit').submit();
		}
	});

    $('footer .back-to-top').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
        return false;
    });

	$('.footer-item .footer-dd-container .has-dropdown').on('click', function () {
		if (util.getViewport() < 768) {
			$(this).siblings('ul').toggle();
			$(this).toggleClass('active');
		}
	});

	//Titleist footer js
	$('.menu-languages .btn-toggle').on('click', function () {
		$(this).parents('.has-children').toggleClass('is-open');
	});

	$('.collapsible-menu-heading').on('click', function () {
		$(this).siblings('.collapsible-menu').toggleClass('is-active');
		if ($(this).find('.collapsible-icon').html() === '+') {
			$(this).find('.collapsible-icon').html('-');
		} else {
			$(this).find('.collapsible-icon').html('+');
		}
	});

	//Titleist header js
	$(document).ready(function () {
		var $body = $('body');
		var $nav = $('.titleist-nav');
		var button;

		function navClose() {
			if ($body.hasClass('nav-is-open')) {
				$body.removeClass('nav-is-open');
				$('html').removeClass('nav-fix');
				$body.find('.modal-overlay').remove();
			}
		}
		$nav.find('.nav-item > a:not(.no-sub)').on('click', function (e) {
			e.preventDefault();
			var settings = {
				speed: 400,
				classname: 'is-active'
			};
			var $navLink = $(this);
			var navItem = $navLink.parent();
			var subMenu = navItem.find('.sub-menu');
			if ($body.width() >= 800) {
				if (navItem.hasClass(settings.classname)) {
					subMenu.slideUp(100, function () {
						navItem.removeClass(settings.classname);
						subMenu.removeAttr('style');
						navClose();
					});
				} else {
					if ($nav.find('.nav-item.is-active').length) {
						$nav.find('.nav-item.is-active').find('.sub-menu').slideUp(100, function () {
							navItem.siblings().removeClass(settings.classname).find('.sub-menu').removeAttr('style');
							navItem.addClass(settings.classname);
							subMenu.hide().slideDown(200, function () {
								subMenu.removeAttr('style');
								navClose();
							});
						});
					} else {
						navItem.addClass(settings.classname);
						subMenu.hide().slideDown(200, function () {
							subMenu.removeAttr('style');
						});
					}
				}
			} else {
				navItem.siblings().removeClass(settings.classname);
				if (navItem.hasClass(settings.classname)) {
					navItem.removeClass(settings.classname);
					navClose();
				} else {
					navItem.addClass(settings.classname);
					if ($('.modal-overlay').length === 0) {
						$body.addClass('nav-is-open').prepend('<div class="modal-overlay" />');
						$('html').addClass('nav-fix');
						$('.modal-overlay').hide().fadeIn(300);
					}
				}
			}
		});
		$body.on('click', '.modal-overlay', function () {
			button = $nav.find('.nav-item.is-active').find('> a');
			button.closest('.nav-item').removeClass('is-active');
			navClose();
		});
		$(document).keyup(function (event) {
			if (event.keyCode === 27) {
				var isOpen = $('.titleist-menu').find('.is-open');
				$nav.find('.nav-item.is-active').removeClass('is-active');
				navClose();
				if ($body.hasClass('menu-is-open')) {
					isOpen.find('.btn-toggle').trigger('click').focus();
				}
				isOpen.find('.is-open').find('.menu-content').attr('aria-hidden', 'true');
			}
		});
	});
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
	// add class to html for css targeting
	$('html').addClass('js');
	if (SitePreferences.LISTING_INFINITE_SCROLL) {
		$('html').addClass('infinite-scroll');
	}
	// load js specific styles
	util.limitCharacters();
	dataLayer.push({'event': 'optimize.activate'});
	console.log('optimize.activate');
}

var pages = {
	account: require('./pages/account'),
	cart: require('./pages/cart'),
	catlanding: require('./pages/catlanding'),
	checkout: require('./pages/checkout'),
	compare: require('./pages/compare'),
	configurator: require('./pages/configurator'),
	product: require('./pages/product'),
	registry: require('./pages/registry'),
	search: require('./pages/search'),
	storefront: require('./pages/storefront'),
	wishlist: require('./pages/wishlist'),
	storelocator: require('./pages/storelocator')
};

var app = {
	init: function () {
		if (document.cookie.length === 0) {
			$('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
		}
		initializeDom();
		initializeEvents();

        // init specific global components
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        window.pdpRecommendationsInit();
		searchplaceholder.init();
        headermenu.init();
        headersearch.init();
        headerslider.init();
        cq.init();
		customdialog.init();
		gateway.init();
		contactUs.init();
        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }
		// Titleist headroom
		var offset = $('#banner').outerHeight();
		if (window.headroom !== undefined) {
			if (window.headerOffset !== undefined && window.headerOffset === offset) {
				return;
			}

			window.headroom.destroy();
			$('#banner').removeData('headroom');

			window.headerOffset = offset;
			window.headroom = new Headroom($('#banner').get(0), {
				'offset': offset,
				'tolerance': 5,
				'classes': {
				'initial': 'banner--fixed',
				'unpinned': 'banner--down'
				},
				onUnpin: function () {
				// Close open navigation when scrolling down
					$('.titleist-nav').find('.nav-item.is-active').removeClass('is-active');
				}
			});

			window.headroom.init();
			$('html').css('margin-top', offset);
		}
	}
};

// general extension functions
(function () {
	String.format = function () {
		var s = arguments[0];
		var i, len = arguments.length - 1;
		for (i = 0; i < len; i++) {
			var reg = new RegExp('\\{' + i + '\\}', 'gm');
			s = s.replace(reg, arguments[i + 1]);
		}
		return s;
	};
})();

// initialize app
$(document).ready(function () {
	app.init();
});
