'use strict';
var validator = require('./validator'),
	util = require('./util'),
	page = require('./page'),
	isMobile = util.isMobile(),
	$form = $('.gateway-form');

var setCookie = function () {
	var nextMonth = new Date();
	nextMonth.setDate(nextMonth.getDate() + 30);
	var expiresDate = nextMonth.toString();
	document.cookie = SitePreferences.GATED_COOKIE_NAME + '=' + SitePreferences.GATED_COOKIE_VALUE + '; path=/; expires=' + expiresDate;
};


var calculateVideoAlignment = function () {
    var $video = $('#ambient-video-background');
    if (!$video) {
        return false;
    }
    var videoAspectRatio = $video[0].videoHeight / $video[0].videoWidth;
    var displayAspectRatio = $(window).height() / $(window).width();
    if (displayAspectRatio <= videoAspectRatio) {
        $video.parents('.gateway-main.video-container').removeClass('vertical-centred');
        $video.parents('.gateway-main.video-container').addClass('horizontal-centred');
    } else {
        $video.parents('.gateway-main.video-container').removeClass('horizontal-centred');
        $video.parents('.gateway-main.video-container').addClass('vertical-centred');
    }
};
$('#ambient-video-background').on('loadedmetadata', function () {
    calculateVideoAlignment();
});
$(window).on('resize', function () {
    if ($('.pt_gateway.home').length) {
        calculateVideoAlignment();
    }
});

exports.init = function () {

    if ($('.pt_gateway.home').length) {
        calculateVideoAlignment();
    }
	if (isMobile) {
		$('body').addClass('smaller');
	}

	$form.submit(function (e) {

		$form.validate();
		if (!$form.valid()) {
			e.preventDefault();
			return false;
		}
		if (!$('#gatewayapprove').is(':checked')) {
			e.preventDefault();
			$('#gatewayemail-acceptance-error').show();
			$('#gatewayemail-acceptance-error').addClass('error');
			return false;
		}
        var options = {
                url: util.ajaxUrl($form.attr('action')),
                method: 'POST',
                cache: false,
                data: $form.serialize()
         };

        if ($('input.gateway-approve').is(':not(:checked)')) {
            e.preventDefault();
            return false;
        } else {
            e.preventDefault();
            $.ajax(options).done(function (response) {
                setCookie();
                page.redirect(Urls.homeShow);
            }).fail(function (xhr, textStatus) {
                // failed
                if (textStatus === 'parsererror') {
                    window.alert(Resources.BAD_RESPONSE);
                    return false;
                } else {
                    window.alert(Resources.SERVER_CONNECTION_ERROR);
                    return false;
                }
            });
        }

	});
	validator.init();
};
