'use strict';

var addToCart = require('./addToCart'),
    customize = require('./customize'),
    ajax = require('../../ajax'),
    imagesLoaded = require('imagesloaded'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    dialog = require('../../dialog'),
    util = require('../../util');


/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('select[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };
    var colorsDropdownExpanded = $('.more-colors-link').hasClass('open');

    progress.show($('#pdpMain'));
    window.isUpdateContent = true;

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            toggle();
            addToCart();
            customize();
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            tooltip.init();
            image.setupCarousel();
            $('#free-returns').on('click', function (e) {
                e.preventDefault();
                dialog.open({
                    url: $(e.target).attr('href')
                });
            });
			$('.product-col-1 .product-image').css({'height': $('.product-content').attr('data-tile-height'), 'min-height': $('.product-content').attr('data-tile-height')});
			var imageposition = $('.product-col-1 .product-image:first').offset().top - 90;
			window.scrollTo({top: imageposition});

            if (window.colorClicked) {
                imagesLoaded('.product-image.main-image').on('always', function () {
                    $('#pdpMain .thumb').removeClass('selected');
                    $($('#pdpMain .thumb').eq(0)).addClass('selected');
                    return true;
                });
            }

            if (colorsDropdownExpanded) {
                var colorsLink = $('.more-colors-link');
                $('.row-hidden').show();
                colorsLink.addClass('open');
                colorsLink.text(Resources.LESS);
            }


            if ($('#add-to-cart').hasClass('disabled')) {
                if ($('.product-detail .select-size .custom-select li').length == 1 && !$('.product-detail .select-size  .custom-select li').hasClass('selected')) {
                    $('.product-detail .select-size .custom-select li').trigger('click');
                }

                if ($('.product-detail .select-width .custom-select li').length == 1 && !$('.product-detail .select-width  .custom-select li').hasClass('selected')) {
                    $('.product-detail .select-width .custom-select li').trigger('click');
                }
            }
            if ($('.product-variations').data('availability-status') == 'NOT_AVAILABLE') {
                $('#add-to-cart').hide();
                $('.selection-error').html($('.custom-order').attr('title'));
                $('.selection-error').show();
            }
            updateWishListURL();
			imagesLoaded('.product-primary-image').on('always', function () {
				$('.product-col-1 .product-image').css({'min-height': '', 'height': ''});
			});
        }
    });
};

function expandCustomSelect(e) {
    $('.content:visible').slideUp('slow');
    $(this)
        .next('.content:hidden')
        .slideDown('slow');
}

function toggle () {
    $('.must-select').each(function () {
        $(this).find('.header-toggle').html($(this).find('li.selected').html());
        $(this).find('.content').hide();
    });
    $('.must-select li.selected').closest('.must-select').removeClass('.not-selected').addClass('selected');

    $('.header').click(expandCustomSelect)
}


function updateWishListURL() {
    if (typeof WISHLIST_URL !== 'undefined') {
        $('.secondary.add-to-wishlist').attr('href', WISHLIST_URL);
    }

    $('.add-to-wishlist').on('click', customize.addToWishlist);
}

module.exports = function () {
    var $pdpMain = $('#pdpMain');

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor, .additional-colors .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        window.colorClicked = false;
        if ($(this).parents('.additional-colors').length) {
            $('body,html').animate(
                {'scrollTop': $('#pdpMain').offset().top - 86}, 600
            );
            window.colorClicked = true;
        }
		var parentContent = $(this).parents('.product-content');
		if (parentContent.length > 0) {
			parentContent.attr('data-tile-height', $('.product-col-1 .product-image').eq(0).height() + 'px');
		}
        updateContent(this.href);
    });
    $pdpMain.on('click', '.product-detail .custom-select li', function () {
        updateContent($(this).attr('value'));
    });

    $pdpMain.on('click', '.more-colors-link', function (e) {
        e.preventDefault();
        var colorsLink = $(this);

        if (colorsLink.hasClass('open')) {
            $('.row-hidden').slideUp();
            colorsLink.removeClass('open');
            colorsLink.text(Resources.MORE);
        } else {
            colorsLink.addClass('open');
            $('.row-hidden').slideDown();
            colorsLink.text(Resources.LESS);
        }
    });

    $pdpMain.on('change', '#SpecialPlayNumber', function () {
        if ($(this).val() != '') {
            updateContent($(this).val());
        } else {
            $('#add-to-cart').addClass('disabled');
        }
    });

    $pdpMain.on('keydown', '.product-detail .custom-select li', function (e) {
        var key = e.keyCode ? e.keyCode : e.which;
        var KEY_CODE_ENTER = 13,
            KEY_CODE_SPACE = 32;
        if ([KEY_CODE_ENTER, KEY_CODE_SPACE].indexOf(key) > -1) {
            e.preventDefault();
            updateContent($(this).attr('value'));
        }
    });

    $pdpMain.on('click', '.product-detail #play-hand .button', function () {
        var btn = $(this);
        if (!btn.hasClass('disabled')) {
            $('#va-orientation option').each(function () {
                if ($(this).html().trim() === btn.data('attr-value')) {
                    updateContent($(this).attr('value'));
                }
            });
        }
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function (e) {
        e.preventDefault();
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());
    });

    $pdpMain.on('keydown', '.header', function (e) {
        var key = e.keyCode ? e.keyCode : e.which;
        var KEY_CODE_ENTER = 13,
        KEY_CODE_SPACE = 32,
        actionKeys = [KEY_CODE_ENTER, KEY_CODE_SPACE];
        if (actionKeys.indexOf(key) > -1) {
            e.preventDefault();
            expandCustomSelect.call(this);
        }
    });

    if ($('#add-to-cart').hasClass('disabled')) {
        if ($('.product-detail .select-size .custom-select li').length == 1 && !$('.product-detail .select-size  .custom-select li').hasClass('selected')) {
            $('.product-detail .select-size .custom-select li').trigger('click');
        }

        if ($('.product-detail .select-width .custom-select li').length == 1 && !$('.product-detail .select-width  .custom-select li').hasClass('selected')) {
            $('.product-detail .select-width .custom-select li').trigger('click');
        }
    }

    toggle();

    updateWishListURL();
};
